


.footer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  background-color: #F5F7F9;
  width: 100%;
  /* height: 1282px; */
  /* padding: 32px 0px 36px 0px; */
}

.footer_box {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 32px 0px 0px 0px;
}

.footer_content {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.left_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-right: 40px;
  transition: all 0.3s ease;
}

.left_content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.hol_info {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.hol_info_wrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.footer_more_info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 24px;
} 

.hol_logo {
  height: 80px;
  width: 160px;
  border-radius: 0px;
}

.hol_contact {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.hol_contact_item {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.hol_contact_item_title {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4%;
  text-align: left;
  color: #162533;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hol_contact_item_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-width: auto;
  max-width: 280px;
  gap: 2px;
  transition: all 0.3s ease;
}

.hol_contact_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.hol_contact_icon svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
 
}

.certificate {
  display: flex;
  flex-direction: row;
  gap: 16px;
  transition: all 0.3s ease;
}

.certificate_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 16px; */
  height: 64px;
}

.certificate_icon a img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer_item_wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.footer_item_title {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.13%;
  text-align: left;
  color: #003366;
  max-width: 212px;
  width: 100%;
  min-width: auto;
}

.footer_item_content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  transition: all 0.3s ease;
}

/* .special_style {
  display: flex;
  flex-wrap: wrap;
  min-width: auto;
  max-width: 100px;
  width: 100%;
  height: 100%;
  max-height: 204px;
  min-height: auto;
} */

.special_style {
  display: flex; 
  flex-wrap: wrap;
  width: 100%;
  min-width: auto;
  max-width: 100px;
  height: 100%;
  max-height: 204px;
  min-height: auto;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.footer_item_text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4%;
  text-align: left;
  color: #162533;
}

.footer_sub_item_list li{
  margin-left: 20px; 
  list-style-type: disc !important; 
  list-style-position: outside !important; 
}

.footer_sub_item {
  display: list-item; 
  font-size: 14px;
  color: black;
}


.copyright {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #003366;
  margin-left: -32px;
}

.copyright_box {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 8px 17px;
  /* margin-right: 40px; */
}

.copyright_text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4%;
  color: white;
  transition: all 0.3s ease;
}

@media screen and (max-width: 354px) {
  .hol_info_wrapper {
    max-height: none !important;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 769px) {
  /* .hol_info_wrapper {

    max-height: none !important;
    box-sizing: border-box;
  } */
   .footer_content {
     padding: 32px 17px;
   }

  .footer_content {
    display: flex;
    flex-direction: column;
  }

  .copyright_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }

  .copyright_text {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4%;
    color: white;
  }
}


@media screen and (max-width: 992px) {
  .hol_info_wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; 
    gap: 20px;
    justify-content: flex-start; 
    max-height: 600px;
    
  }

  .left_content_wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }

  .footer_item_title {
    max-width: 152px;
  }
  .special_style {
    max-width: auto;
    height: 100%;
    max-height: fit-content;
    min-height: auto;
    box-sizing: border-box;
  }

  .hol_info_wrapper > *:nth-child(4) {
    order: 5; /* Event */
    max-width: none !important;
  }

  .hol_info_wrapper > *:nth-child(5) {
    order: 4; /* RCS */
    max-width: none;
  }

  .certificate {
    display: flex;
    flex-wrap: wrap; 
    gap: 16px; 
    max-width: 180px;
    margin-top: -20px;
  }

  .certificate_icon {
    width: auto;
    height: auto;
  }

  .certificate > :nth-child(1) {
    flex: 0 1 100%; 
  }

  /* .certificate > :nth-child(2),
  .certificate > :nth-child(3),
  .certificate > :nth-child(4) {
    flex: 0 1 calc(50% - 40px);
  } */
}



@media screen and (max-width: 1200px){
  .special_style {
    max-width: fit-content;
    height: 100%;
    max-height: fit-content;
    min-height: auto;
    box-sizing: border-box;
  }
}






