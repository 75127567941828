
.modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  z-index: 1001;
}

.modal::-webkit-scrollbar {
  display: none !important;
}

.request_form_modal {
  background: #F5F7F9;
  margin: auto;
  border: 1px solid #888;
  width: 100%;
  position: fixed;
  height: 100vh;
  overflow: scroll;
  top: 0;
  z-index: 1002;
  border-radius: 8px;

  .container {
    background-color: white;
    margin: 8px;

    .selectDateSection {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid #CFD9E2; 
      padding: 24px;

      .selectDate {
        color: #2B4156;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 28px;
      }
      .calendar {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .selectTimeSection {
      border-radius: 8px;
      border: 1px solid #CFD9E2; 
      padding: 24px;
      overflow: auto;

      .detailSection {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .durationTitle, .placeTitle {
          color:  #2B4156;
          font-size: 16px;
          font-weight:500;
          line-height: 24px; /* 150% */ 
        }

        .durationValue, .placeValue {
          background: #F5F7F9;
          width: 122px;
          color:  #2B4156;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          border: 0;
          padding: 8px 0px;
          
          option {
            font-family: "Nunito Sans"; 
            font-style: normal;
            font-weight: 400;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        select {
          font-family: "Nunito Sans"; 
          /* for Firefox */
          -moz-appearance: none;
          /* for Chrome */
          -webkit-appearance: none;

          background-image: url('../public/assets/ic-caret-down.svg') !important;
          background-repeat: no-repeat !important;
          background-position: right 8px center !important;
          background-size: 24px !important;
        }
        
        /* For IE10 */
        select::-ms-expand {
          display: none;
        }
      }

      .timeTitle {
        color: #2B4156;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 24px;
      }

      .timeItem {
        cursor: pointer;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F7F9;
        font-size: 16px;
        font-weight: 600;
        color: #2B4156;
      }

      .timeItem:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .timeSection {
      width: 100%;
    }
  }
}

.request_form_modal::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none !important;
}

@media (min-width: 576px) {
  .request_form_modal {
    position: relative;
    height: fit-content;
  }
}

/** tab styles */
@media screen and (min-width: 992px){
  .modal {
    padding-left: auto;
    padding-right: auto;
  }
  
  .request_form_modal {
    width: 770px;
    max-height: 915px;
    margin-left: auto;
    margin-right: auto;
    position: static;

    .container {
      margin: 24px;
      display: flex;

      .selectDateSection {
        width: 60%;
      }
      .selectTimeSection {
        width: 40%;
        max-height: 454px;
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  img {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
    height: 24px;
    width: 24px;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.buttonSubmit {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 24px 24px 0;

  button {
    background: #036;
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    padding: 12px 24px;
    width: 130px;
    height: 48px;
  }

  button:disabled {
    opacity: 0.5;
  }
}

.noTime {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #CFD9E2;
}