
@use "fonts";

.modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  z-index: 1001;
}

.modal::-webkit-scrollbar {
  display: none !important;
}

.request_form_modal {
  background-color: #fefefe;
  margin: auto;
  padding: 24px 36px 36px 24px;
  border: 1px solid #888;
  width: 100%;
  position: fixed;
  height: 100vh;
  overflow: scroll;
  top: 0;
  z-index: 1002;
}

.request_form_modal::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none !important;
}

.request_form_modal > .header {
  display: flex;
  height: 32px;
}

.header > div:first-child {
  height: 53px;
  width: 100%;
  /* width: 311px; */
  left: 32px;
  top: 32px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #003366;
}

.header > img {
  width: 24px;
  height: 24px;
  justify-self: flex-end;
  cursor: pointer;
}

.request_form_modal > div:nth-child(2) {
  margin: 28px 25px 8px 0;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #7C7C7C;
}

.input_group > input {
  height: 48px;
  width: 100%;
  border: 1px solid #7C7C7C;
  border-radius: 5px;
  padding: 12px;
  margin-top: 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.input_group > input:focus {
  border: 1.5px solid var(--primary);
  outline: none;
}

.input_group > input.error {
  border-color: #F8403A;
}

.form.submitted > .input_group > input[type="email"]:invalid,
.form.submitted > .input_group > input[type="text"]:invalid,
.form.submitted > .input_group > input[type="tel"]:invalid {
  border-color: red;
}

.form > .input_group > .error {
  display: none;
}

.form.submitted > .input_group > input[type="email"]:invalid + .error,
.form.submitted > .input_group > input[type="text"]:invalid + .error,
.form.submitted > .input_group > input[type="tel"]:invalid + .error {
  color: #F8403A;
  display: flex;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
  gap: 10px;
  align-items: center;
}

.input_group > input::-webkit-input-placeholder {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.input_group > input::-ms-input-placeholder {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.input_group > input::placeholder {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.input_group > select {
  border: 1px solid #bbbbbb;
  width: 100%;
  height: 48px;
  border: 1px solid #A8B8C7;
  border-radius: 5px;
  padding: 12px;
  @include fonts.mhu-header-5;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background: url("../public/assets/arrow_down_dropdown.png") no-repeat right
    #ddd;
  // background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  background-position-x: 95%;
  background-color: transparent;
  margin-top: 40px;

  &::placeholder  {
    color: #607A93;
  }

  > option {
    background-color: #ffffff;
  }
}

.request_form_modal.error {
  position: fixed;
  bottom: 0;
  max-height: 900px;
}

.request_form_modal.success {
  max-width: 500px;
}

.form > .error > img {
  width: 18px;
  height: 18px;
}

/** deadline target, component later */
.request_form_modal button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 20px;

  width: 100%;
  height: 48px;

  /* Main/Primary */
  /* Inside auto layout */
  flex: none;
  order: 7;
  flex-grow: 0;
  cursor: pointer;
  margin-top: 32px;
}


.solid {
  /* Main/Primary */
  background: var(--primary);
  border-radius: 5px;
  /* Inside auto layout */
  color: #FFFFFF;
}

.solid:hover {
  background-color: #002345;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.solid > img {
  animation: rotation 2s infinite linear;
}

.solid:disabled {
  background-color: #002345;
}

.clear {
  background: transparent;
  border-radius: 5px;
  width: 57px;
  /* Inside auto layout */
  color: var(--primary);
  margin-top: 40px;
}

.clear:hover {
  color: #002345;
  background-color: transparent;
}

.success_notif {
  height: 118px;
  width: 100%;
  border-radius: 0px;
  padding: 8px 15px 10px 10px;
  background-color: #D3E9FF;
  border: 2px solid #39A3DF;
  color: #2292D3;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

@media screen and (max-width: 324px) {
  .success_notif {
    height: fit-content;
  }
}

@media (min-width: 576px) {
  .request_form_modal {
    position: relative;
    height: fit-content;
  }
}

/** tab styles */
@media screen and (min-width: 992px){
  .modal {
    padding-left: auto;
    padding-right: auto;
  }
  
  .request_form_modal {
    width: 770px;
    max-height: 915px;
    padding: 32px;
    margin-left: auto;
    margin-right: auto;
    position: static;
  }

  .request_form_modal.error, .request_form_modal.success {
    position: relative;
    top: -5vh;
    max-height: 900px;
  }

  .request_form_modal > .header {
    height: 53px;
  }
  
  .header > div:first-child {
    left: 32px;
    top: 32px;
    font-size: 40px;
    line-height: 58px;
    color: #003366;
  }


  .request_form_modal > div:nth-child(2) {
    width: 100%;
    margin-top: 32px;
    margin-right: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }

  .input_group > input {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    height: 48px;
    margin-top: 32px;
  }


  .success_notif {
    height: 68px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.form, .success_container {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.success_container {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  align-items: center;
  justify-content: center;

  img {
      width: 140px;
      margin-bottom: 24px;
  }

  p {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #2B4156;
      margin-bottom: 24px;
  }

  button {
      margin-top: 0px
  }
}